var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "화학자재 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-material", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && _vm.isUpdate,
                            label: "자재코드/명",
                            name: "materialCd",
                            type: "codename",
                            plantCd: _vm.data.plantCd,
                          },
                          model: {
                            value: _vm.data.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialCd", $$v)
                            },
                            expression: "data.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "화학자재일련번호",
                            name: "mdmChemMaterialId",
                          },
                          model: {
                            value: _vm.data.mdmChemMaterialId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mdmChemMaterialId", $$v)
                            },
                            expression: "data.mdmChemMaterialId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "납품업체",
                            name: "deliveryVendorCd",
                          },
                          model: {
                            value: _vm.data.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deliveryVendorCd", $$v)
                            },
                            expression: "data.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제조업체",
                            name: "mfgVendorCd",
                          },
                          model: {
                            value: _vm.data.mfgVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mfgVendorCd", $$v)
                            },
                            expression: "data.mfgVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "보관장소",
                            name: "storagePlace",
                          },
                          model: {
                            value: _vm.data.storagePlace,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "storagePlace", $$v)
                            },
                            expression: "data.storagePlace",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.data.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "propertiesStateCd", $$v)
                            },
                            expression: "data.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비산성(고체)",
                            name: "fugacity",
                          },
                          model: {
                            value: _vm.data.fugacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "fugacity", $$v)
                            },
                            expression: "data.fugacity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "끓는점(액체,기체)",
                            name: "boilPoint",
                          },
                          model: {
                            value: _vm.data.boilPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "boilPoint", $$v)
                            },
                            expression: "data.boilPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "녹는점",
                            name: "meltingPoint",
                          },
                          model: {
                            value: _vm.data.meltingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "meltingPoint", $$v)
                            },
                            expression: "data.meltingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "분자량",
                            name: "col7",
                          },
                          model: {
                            value: _vm.data.col7,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col7", $$v)
                            },
                            expression: "data.col7",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.data.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usageCd", $$v)
                            },
                            expression: "data.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable && _vm.data.usageCd == "UC00000032",
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.data.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usageEtc", $$v)
                            },
                            expression: "data.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.mixFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "mixFlag",
                            label: "물질구성",
                          },
                          model: {
                            value: _vm.data.mixFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mixFlag", $$v)
                            },
                            expression: "data.mixFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비중",
                            name: "specificGravity",
                          },
                          model: {
                            value: _vm.data.specificGravity,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specificGravity", $$v)
                            },
                            expression: "data.specificGravity",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "CAS No.",
                            name: "casNo",
                          },
                          model: {
                            value: _vm.data.casNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "casNo", $$v)
                            },
                            expression: "data.casNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "고유번호",
                            name: "serialNo",
                          },
                          model: {
                            value: _vm.data.serialNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "serialNo", $$v)
                            },
                            expression: "data.serialNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "KE 번호",
                            name: "keNo",
                          },
                          model: {
                            value: _vm.data.keNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "keNo", $$v)
                            },
                            expression: "data.keNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "HS번호",
                            name: "hsNo",
                          },
                          model: {
                            value: _vm.data.hsNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hsNo", $$v)
                            },
                            expression: "data.hsNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.ynFlagItems,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.data.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "licensingFlag", $$v)
                            },
                            expression: "data.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.ynFlagItems,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.data.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dangerFlag", $$v)
                            },
                            expression: "data.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.useFlagItems,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                      },
                      [
                        _c("c-radio", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.psmYnItems,
                            label: "PSM대상여부",
                            name: "psmFlag",
                          },
                          model: {
                            value: _vm.data.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "psmFlag", $$v)
                            },
                            expression: "data.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                      },
                      [
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "제조",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.makeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "makeFlag", $$v)
                            },
                            expression: "data.makeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "수입",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.impFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "impFlag", $$v)
                            },
                            expression: "data.impFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "구매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.buyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "buyFlag", $$v)
                            },
                            expression: "data.buyFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "사용",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.usingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usingFlag", $$v)
                            },
                            expression: "data.usingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "수출",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.expFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "expFlag", $$v)
                            },
                            expression: "data.expFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "판매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.salesFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "salesFlag", $$v)
                            },
                            expression: "data.salesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-table",
                {
                  ref: "deptTable",
                  attrs: {
                    title: "취급부서 목록",
                    tableId: "deptTable",
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                    columnSetting: false,
                    selection: "multiple",
                    rowKey: "deptCd",
                    filtering: false,
                    usePaging: false,
                    hideBottom: true,
                    gridHeight: "305px",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && !_vm.isUpdate
                            ? _c("c-btn", {
                                attrs: { label: "추가", icon: "add" },
                                on: { btnClicked: _vm.addrow },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.isUpdate
                            ? _c("c-btn", {
                                attrs: { label: "저장", icon: "save" },
                                on: { btnClicked: _vm.saveDepts },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.isUpdate
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.removeRow },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            counter: true,
                            maxlength: 500,
                            editable: _vm.editable,
                            rows: 11,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.data.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "remarks", $$v)
                            },
                            expression: "data.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }